import styled from 'styled-components'

export const TradeshowPageStyles = styled.div`
  min-height: calc(100vh - 85px);
  margin: 20px auto;
  max-width: 1440px;
  padding: 0 20px;

  .tab-container {
    max-width: 600px;
  }
  .uploaded-photo-large {
    width: 100%;
    max-height: calc(100vh - 150px);
    object-fit: contain;
  }
  .uploaded-photo-small {
    width: 120px;
    border-radius: 5px;
    height: 70px;
    object-fit: cover;
  }
  .close-icon-img {
    width: 1.25rem;
    height: 1.25rem;
  }
`
export const AllUsersTabStyles = styled.div`
  .pick-winner-btn {
    padding: 0.75rem 3rem;
    font-size: 1rem;
  }
  .table-container .delete-btn {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    padding: 0.5rem 1.5rem;
    word-break: keep-all;
  }
  .delete-modal-content {
    h2 {
      font-weight: 500;
    }
    .close-icon {
      font-size: 40px;
      position: absolute;
      right: 0;
      top: 0;
      line-height: 0;
      cursqor: pointer;
    }
    .app-sec-btn {
      font-size: 1rem;
      color: #000;
    }
    .app-pri-btn {
      padding: 0.5rem 2rem;
      font-size: 1rem;
    }
  }
`

export const WinnersTabStyles = styled.div``

export const TableStyles = styled.div`
  .table-card-container {
  width: 100%;
  margin: 0 auto;
  }

  .table-view {
    width: 100%;
    display: none;
  }

  .card-view {
    display: flex;
    flex-wrap: wrap;
  }

  .card {
    border: 1px solid #ddd;
    padding: 1rem;
    margin: 1rem;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    width: 100%;
  }
  .row-value{
    word-break: break-all;
  }

  .table-view table {
    width: 100%; /* Ensure the table takes up the full width */
    border-collapse: collapse;
  }

  .table-view th, .table-view td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
    word-break: break-all;
  }

  .table-view th {
    background-color: #f4f4f4;
    font-weight: bold;
  }

  .table-view tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .table-view tr:hover {
    background-color: #f1f1f1;
  }

  @media(min-width: 768px) {
    .table-view {
      display: table;
    }
    .card-view {
      display: none;
  }
`

import React from 'react'
import { QrDetailCard, QrCodeDetails } from '../QrDetailCard'

export const SingleQrCode = React.forwardRef((props: any, ref) => {
  return (
    <>
      {/* @ts-ignore */}
      <div className="flex qr-poster single-poster" ref={ref}>
        <QrDetailCard memberInfo={props.memberInfo} />
      </div>
    </>
  )
})

export const MultipleQrCodePoster = React.forwardRef((props: any, ref) => {
  const arr = [1, 1, 1, 1, 1, 1]
  return (
    //@ts-ignore
    <div ref={ref} className="container-grid">
      {arr.map((_, idx) => (
        <div key={idx} className="flex qr-poster grid-borders multiple-posters">
          {/* @ts-ignore */}
          <QrCodeDetails memberInfo={props.memberInfo} />
        </div>
      ))}
    </div>
  )
})

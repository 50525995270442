import DashboardWrapper from '../view/Dashboard/DashboardWrapper'
import NavigateToLogin from '../view/Auth/NavigateToLogin'
import { Navigate } from 'react-router-dom'
import { MyDevices } from '../components/my-devices/MyDevices'
import MyCredentials from '../components/my-credentials'
import { Dashboard } from '../components/dashboard'
import GamesAndActivities from '../components/gamesAndActivity'
import { Login } from '../components/login'
import { Signup } from '../components/signup'
import { ConfirmAccount } from '../components/confirmAccount'
import { SocialLoginSuccess } from '../components/socialLogin/socialLoginSuccess'
import { EmailExists } from '../components/emailExists'
import { PageNotFound, Error, ExpiredSession } from 'esstart-components'
import { ResetPassword } from '../components/resetPassword/ResetPassword'
import { CommonQr } from '../components/commonQr'
import { MembersOnly } from '../components/membersOnly'
import { TradeshowPage } from '../components/tradeshow'
import { TradeshowWinners } from '../components/tradeshow/Winners'

// console.log
export const routes = (authData: any) => [
  {
    path: '/',
    element:
      authData?.authStatus === 1 ? (
        <DashboardWrapper authData={authData} headerShow={true} />
      ) : (
        <NavigateToLogin />
      ),
    children: [
      { path: '/', element: <Navigate to="/dashboard" /> },
      {
        path: 'dashboard',
        index: true,
        element: <Dashboard />,
      },
      {
        path: 'mydevices',
        index: true,
        element: <MyDevices />,
      },
      {
        path: 'allproducts',
        index: true,
        element: <GamesAndActivities />,
      },
      {
        path: 'confirm-account',
        index: true,
        element: <ConfirmAccount />,
      },
      {
        path: 'credentials',
        index: true,
        element: <MyCredentials />,
      },
      {
        path: 'personalized-qr',
        index: true,
        element: <CommonQr />,
      },
      {
        path: 'tradeshow',
        index: true,
        element: process.env.REACT_APP_RAFFLE_ADMIN_ID?.split(',').includes(
          authData?.userInfoData?.userId
        ) ? (
          <TradeshowPage />
        ) : (
          <PageNotFound />
        ),
      },
      {
        path: 'tradeshow-winners',
        index: true,
        element: process.env.REACT_APP_RAFFLE_ADMIN_ID?.split(',').includes(
          authData?.userInfoData?.userId
        ) ? (
          <TradeshowWinners />
        ) : (
          <PageNotFound />
        ),
      },
      {
        path: 'members-section',
        index: true,
        element:
          authData?.userInfoData?.hasMembership === true ? (
            <MembersOnly />
          ) : (
            <PageNotFound />
          ),
      },
      {
        path: '*',
        index: true,
        element: <PageNotFound />,
      },
    ],
  },
  {
    path: '/',
    element:
      authData?.authStatus === 1 ? (
        <Navigate to="/dashboard" />
      ) : (
        <DashboardWrapper authData={authData} headerShow={false} />
      ),
    children: [
      {
        path: 'login',
        index: true,
        element: <Login />,
      },
      {
        path: 'signup',
        index: true,
        element: <Signup />,
      },
      {
        path: 'confirm-account',
        index: true,
        element: <ConfirmAccount />,
      },
      {
        path: 'social-login-success',
        index: true,
        element: <SocialLoginSuccess />,
      },
      {
        path: 'emailExists',
        index: true,
        element: <EmailExists />,
      },
      {
        path: 'error',
        index: true,
        element: <Error />,
      },
      {
        path: 'reset-password',
        index: true,
        element: <ResetPassword />,
      },
      {
        path: '*',
        index: true,
        element: <PageNotFound />,
      },
    ],
  },
  {
    path: '/session-expired',
    element: <ExpiredSession />,
  },
]

import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { purchaseProduct } from '../../redux/payment'
import { AuthContext } from 'esstart-components'

interface BuyMembershipButtonProps {
  btnText: string
  className?: string
  btnClickHandler?: () => void
}

export const BuyMembershipButton = ({
  btnText,
  btnClickHandler,
  className,
}: BuyMembershipButtonProps) => {
  const dispatch: any = useDispatch()
  const authWrapper: any = useContext(AuthContext)

  const paymentSlice = useSelector((store: any) => store?.payments)
  const membershipPlan = paymentSlice?.strProductsList?.find(
    (prd: any) =>
      prd?.metadata?.distinctName === 'membership' &&
      (!!authWrapper?.userInfoData?.showDiscountedMemberPrice
        ? prd?.priceMetadata?.discountedPrice === 'true'
        : prd?.priceMetadata?.latest === 'true')
  )

  const purchasePlan = async () => {
    const checkoutSessionResponse = await dispatch(
      purchaseProduct({
        strCId: authWrapper?.userInfoData?.strCId,
        username: authWrapper?.userInfoData?.email,
        customerName: authWrapper?.userInfoData?.name,
        line_items: [{ price: membershipPlan?.priceId, quantity: 1 }],
        success_url: `${process.env.REACT_APP_WEBAPP_URL}dashboard?payment=success`,
        cancel_url: `${process.env.REACT_APP_WEBAPP_URL}dashboard`,
        distinctName: 'membership',
        // productId: slideshow[0]._id,
        // planId: filteredPlan?.metadata?.planId,
        paymentMode: 'subscription',
        isCustomerSubscribed: authWrapper?.userInfoData?.hasMembership,
        //@ts-ignore
        tolt_referral: window?.tolt_referral,
      })
    )
    window.open(checkoutSessionResponse?.payload?.payload?.data?.url, '_self')
  }

  return (
    <button
      className={`cursor-pointer ${className || ''}`}
      onClick={purchasePlan}
    >
      {btnText}
    </button>
  )
}

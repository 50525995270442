import React, { useContext, useState } from 'react'
import { BillingTabStyles } from './style'
import { cancelUserSubscription } from '../../redux/payment'
import { useDispatch, useSelector } from 'react-redux'
import { AuthContext, Loader, Modal } from 'esstart-components'
import closeIcon from '../../assets/images/close_icon.svg'
import { errorToast, successToast } from '../toasts'
import { getFormattedDate } from '../../utils/getFormattedDate'
import { getUserInfo } from '../../redux/settings'

export const BillingTab = () => {
  const dispatch = useDispatch<any>()
  const authWrapper: any = useContext(AuthContext)
  const isPaymentApiLoading = useSelector(
    (store: any) => store?.payments?.isPaymentApiLoading
  )

  const [cancelSubscriptionModal, toggleCancelSubscriptionModal] =
    useState(false)

  const cancelSubscriptionClickHandler = async () => {
    const res = await dispatch(cancelUserSubscription())
    if (res?.payload?.status === 'success') {
      successToast('Cancellation requested successfully!')
    } else {
      errorToast('Something went wrong!')
    }

    authWrapper.setUserInfoData((prev: any) => ({
      ...prev,
      cancellationRequestedAt: res?.payload?.data?.cancellationRequestedAt,
    }))
    closeCancelSubscriptionModal()
  }

  const closeCancelSubscriptionModal = () => {
    toggleCancelSubscriptionModal(false)
  }

  return (
    <BillingTabStyles className="mt-4">
      {isPaymentApiLoading && <Loader />}
      {cancelSubscriptionModal && (
        <Modal open={true} size="xl" className="cancel-subscription-modal">
          <div className="d-flex gap-4 align-items-center justify-content-between">
            <h3 className="heading">Cancel Membership</h3>
            <img
              src={closeIcon}
              alt="close"
              className="close-icon cursor-pointer"
              onClick={closeCancelSubscriptionModal}
            />
          </div>
          <p className="mt-5">
            Are you sure you want to cancel the membership?
          </p>
          <div className="btn-container d-flex gap-4 justify-content-around flex-wrap mt-4">
            <button
              className="app-sec-btn"
              onClick={closeCancelSubscriptionModal}
            >
              Cancel
            </button>
            <button
              className="app-pri-btn"
              onClick={cancelSubscriptionClickHandler}
            >
              Yes, Cancel
            </button>
          </div>
        </Modal>
      )}

      <div className="d-flex flex-column gap-5">
        {!!authWrapper?.userInfoData?.cancellationRequestedAt && (
          <h3 className="color-red">
            You have requested for cancellation on{' '}
            <span>
              {getFormattedDate(
                authWrapper?.userInfoData?.cancellationRequestedAt
              )}
            </span>
          </h3>
        )}

        <h3>
          Your Current Membership Status:{' '}
          <span className="text-capitalize">
            {authWrapper?.userInfoData?.subscriptionStatus || '-'}
          </span>
        </h3>
        {!!authWrapper?.userInfoData?.hasMembership &&
          !authWrapper?.userInfoData?.cancellationRequestedAt && (
            <div className="btn-wrappper">
              <button
                className="app-pri-btn cancel-sub-btn"
                onClick={() => toggleCancelSubscriptionModal(true)}
              >
                Cancel Membership
              </button>
            </div>
          )}
      </div>
    </BillingTabStyles>
  )
}

import { useRef, useContext, useEffect } from 'react'
import './style.css'
import { useReactToPrint } from 'react-to-print'
import { SingleQrCode, MultipleQrCodePoster } from './QrCode/SingleQrCode'
import { Buttons } from 'esstart-components'
import { TableCard } from './TableCard'
import multipleQrIcon from '../../../assets/images/multiple-qr-thumbnail.png'
import tableCardIcon from '../../../assets/images/table-card.png'
import { AuthContext } from 'esstart-components'
import { QrCodeImage } from '..'
import { useSelector, useDispatch } from 'react-redux'
import { getMembershipInfo } from '../../../redux/products'
import QRCode from 'react-qr-code'

const QrCodePrints = () => {
  const dispatch: any = useDispatch()
  const singleQrRef = useRef(null)
  const multipleQrRef = useRef(null)
  const tableCardRef = useRef(null)
  const posterRef = useRef(null)
  const qrRef = useRef(null)

  const { productsList: userProductsList, memberInfo } = useSelector(
    (store: any) => store?.products
  )

  const memberMailerEvent = userProductsList.find(
    (prds: any) => prds?.distinctName === 'member-mailer'
  )

  useEffect(() => {
    if (!!memberMailerEvent?._id) {
      dispatch(getMembershipInfo({ mailerId: memberMailerEvent?._id }))
    }
  }, [memberMailerEvent?._id])

  const authWrapper = useContext(AuthContext)
  const landScapePageStyle = `
  @media print {
    @page {
      size: landscape;
      margin: 0;
    }
    .qr-code-txt, .numbering {
      background-color: #ff7200;
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
  }`

  const singleQr = useReactToPrint({
    pageStyle: landScapePageStyle,
    //@ts-ignore
    // content: () => singleQrRef.current,
    contentRef: singleQrRef,
  })

  const multipleQr = useReactToPrint({
    pageStyle: landScapePageStyle,
    //@ts-ignore
    // content: () => multipleQrRef.current,
    contentRef: multipleQrRef,
  })
  const printTableCard = useReactToPrint({
    //@ts-ignore
    // content: () => tableCardRef?.current,
    contentRef: tableCardRef,
  })

  const downloadQrImage = () => {
    //@ts-ignore
    const svg = qrRef.current.querySelector('svg')
    const serializer = new XMLSerializer()
    const source = serializer.serializeToString(svg)
    const img = new Image()
    const svgBlob = new Blob([source], { type: 'image/svg+xml;charset=utf-8' })
    const url = URL.createObjectURL(svgBlob)
    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')!
      ctx.drawImage(img, 0, 0)
      URL.revokeObjectURL(url)
      canvas.toBlob((blob: any) => {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'qrcode.jpeg'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }, 'image/jpeg')
    }
    img.src = url
  }

  const cardArr = [
    {
      title: 'Single Page QR',
      icon: `${process.env.REACT_APP_GUEST_WEBSITE_URL}?lookup=${authWrapper?.userInfoData?.commonQrCodeId}`,
      clickFunction: singleQr,
      downloadFunction: downloadQrImage,
    },
    {
      title: '3 x 2 Page QR',
      icon: multipleQrIcon,
      clickFunction: multipleQr,
    },
    // {
    //   title: 'Poster Page',
    //   icon: posterPageIcon,
    //   clickFunction: printPoster,
    // },
    {
      title: 'Table Card',
      icon: tableCardIcon,
      clickFunction: printTableCard,
    },
  ]

  return (
    <>
      <div className="download-card-main">
        <div className="downloads-wrapper">
          {cardArr.map((data, index) => (
            <div key={index} className="downloads-card-wrapper">
              <div className="download-card">
                <p>{data.title}</p>
                {index === 0 ? (
                  <QrCodeImage className="card-icon" />
                ) : (
                  <img
                    className="card-icon"
                    src={data.icon}
                    alt="download-icon"
                  />
                )}
              </div>
              <div className="print-btn">
                <Buttons
                  text="PRINT"
                  bgColor="#ff7200"
                  color="#fff"
                  fontSize="14px"
                  borderRadius={true}
                  padding="8px 20px"
                  onClick={data.clickFunction}
                />
                {data?.downloadFunction ? (
                  <Buttons
                    text="DOWNLOAD"
                    bgColor="#fff"
                    border="1px solid #ff7200"
                    color="#ff7200"
                    fontSize="14px"
                    borderRadius={true}
                    padding="8px 20px"
                    onClick={data.downloadFunction}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="d-none">
        <SingleQrCode memberInfo={memberInfo} ref={singleQrRef} />
      </div>
      <div className="d-none">
        <MultipleQrCodePoster memberInfo={memberInfo} ref={multipleQrRef} />
      </div>
      <div className="d-none">
        <TableCard memberInfo={memberInfo} ref={tableCardRef} />
      </div>
      <div className="d-none">
        <div ref={qrRef}>
          <QRCode
            id="QRCode"
            size={500}
            style={{
              height: 'auto',
              maxWidth: '100%',
            }}
            value={`${process.env.REACT_APP_GUEST_WEBSITE_URL}?lookup=${authWrapper?.userInfoData?.commonQrCodeId}`}
            viewBox={`0 0 500 500`}
          />
        </div>
      </div>
    </>
  )
}
export default QrCodePrints

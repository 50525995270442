import React from 'react'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import {
  Header,
  navData,
  getCookie,
  Footer,
  setCookie,
} from 'esstart-components'

const DashboardWrapper = ({ authData, headerShow }: any) => {
  const username: any = getCookie('username')
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const moderatorCode = searchParams.get('mCode')
  if (!!moderatorCode) {
    setCookie('mCode', moderatorCode)
  }
  // const toltRef = Cookies.get('ref')
  // Cookies.remove('ref')
  // Cookies.remove('ref', { domain: '.esstart.com' })
  // if (!!toltRef && toltRef !== 'null' && toltRef !== 'undefined') {
  //   searchParams.set('ref', toltRef)
  //   window.location.search = searchParams.toString()
  // }
  return (
    <>
      {headerShow &&
        !['/tradeshow', '/tradeshow-winners'].includes(
          window.location.pathname
        ) && (
          <Header
            navData={navData}
            username={username}
            navigate={navigate}
            authData={authData}
          />
        )}
      <Outlet />
      {headerShow && (
        <>
          <Footer />
        </>
      )}
    </>
  )
}
export default DashboardWrapper

import React, { SyntheticEvent } from 'react'
import QRCode from 'react-qr-code'
import { FormControl, Select, MenuItem } from '@mui/material'
import { errorToast, successToast } from '../toasts'
import { AuthContext } from 'esstart-components'
import { useSelector, useDispatch } from 'react-redux'
import { editUserInfo } from '../../redux/settings'
import warningIcon from '../../assets/images/warning-icon.svg'
import './dashboard.css'

export const CommonQrWrapper = () => {
  const authWrapper: any = React.useContext(AuthContext)
  const products: any = useSelector((state: any) => state.products)
  const dispatch: any = useDispatch()

  const [customUrl, setCustomUrl] = React.useState({
    value: '',
    error: '',
  })

  React.useEffect(() => {
    if (authWrapper?.userInfoData?.commonQrUrl) {
      setCustomUrl((prev) => ({
        ...prev,
        value: authWrapper?.userInfoData?.commonQrUrl,
      }))
    }
  }, [authWrapper?.userInfoData?.commonQrUrl])

  const unUsedFilteredEvents = products?.productsList?.filter((event: any) => {
    const showDate = new Date(event?.showDate)
    const currentDate = new Date()
    const differenceInTime = currentDate.getTime() - showDate.getTime()
    const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24))

    return differenceInDays < 11 || event?.productStatus === 'test'
  })

  const changeConnectedQrCode = async (event: any) => {
    const value = event.target.value
    const payload =
      value === 'defaultUrl'
        ? { isCommonQrAttachedWithUrl: true }
        : { commonQrCodeAttachedWith: value, isCommonQrAttachedWithUrl: false }
    const res = await dispatch(
      editUserInfo({
        commonQrCodeId: authWrapper?.userInfoData?.commonQrCodeId,
        ...payload,
      })
    )
    if (res?.payload?.data?.status === 'success') {
      authWrapper.setUserInfoData((prev: any) => ({
        ...prev,
        ...payload,
      }))
      successToast('QR attached to new event successfully!')
    } else {
      errorToast('Something went wrong!')
    }
  }

  const changeCommonQrCustomUrl = (e: SyntheticEvent) => {
    const value = (e.target as HTMLInputElement).value
    if (!value.startsWith('http')) {
      setCustomUrl((prev) => ({
        ...prev,
        error: 'Please enter a valid URL',
      }))
      return
    }
    setCustomUrl((prev) => ({
      ...prev,
      error: '',
    }))
    authWrapper.setUserInfoData((prev: any) => ({
      ...prev,
      commonQrUrl: value,
    }))
    dispatch(
      editUserInfo({
        commonQrUrl: value,
      })
    )
  }

  return (
    <>
      <p className="qr-heading txt-center">
        <b>Your Master QR Code</b>
      </p>
      <div className="qr-content">
        <div className="qr-img-container mt-1rem">
          <QRCode
            id="QRCode"
            size={500}
            style={{
              height: 'auto',
              maxWidth: '100%',
              width: '100%',
            }}
            value={`${process.env.REACT_APP_GUEST_WEBSITE_URL}?lookup=${authWrapper?.userInfoData?.commonQrCodeId}`}
            viewBox={`0 0 500 500`}
          />
        </div>
        <div className="mt-1rem">
          <p className="txt-center">
            Attach this QR with any of your event by selecting from dropdown
          </p>
          <div className="txt-center">
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={
                  authWrapper?.userInfoData?.isCommonQrAttachedWithUrl
                    ? 'defaultUrl'
                    : authWrapper?.userInfoData?.commonQrCodeAttachedWith
                }
                onChange={changeConnectedQrCode}
                displayEmpty
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="defaultUrl">
                  {customUrl.value || 'Default URL (not set)'}
                </MenuItem>
                {unUsedFilteredEvents?.length > 0 &&
                  unUsedFilteredEvents?.map((prd: any) => (
                    <MenuItem key={prd?._id} value={prd?.activityCode}>
                      {prd?.showName}: {prd?.activityCode}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <div>
              {window.location.pathname === '/members-section' &&
                !!authWrapper?.userInfoData?.isCommonQrAttachedWithUrl &&
                !customUrl.value && (
                  <div className="warning-bar d-flex gap-1">
                    <img src={warningIcon} alt="warning icon" />
                    <p>
                      Default URL is not set. Please click on MANAGE to set the
                      URL.
                    </p>
                  </div>
                )}
            </div>
          </div>
          {!!authWrapper?.userInfoData?.isCommonQrAttachedWithUrl &&
          window.location.pathname === '/personalized-qr' ? (
            <div className="mt-2">
              <label className="common-qr-custom-url">
                <b>Default URL</b>
                <input
                  type="url"
                  className="app-input w-full"
                  placeholder="https://www.esstart.com/"
                  value={customUrl.value}
                  onChange={(e) =>
                    setCustomUrl((prev) => ({ ...prev, value: e.target.value }))
                  }
                  onBlur={changeCommonQrCustomUrl}
                />
                {!!customUrl.error && (
                  <small className="color-red">{customUrl.error}</small>
                )}
              </label>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  )
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post, get } from 'esstart-components'

export const paymentSliceName = 'payments'

interface IState {
  isPaymentApiLoading: boolean
  strProductsList: any
  priceModalState: boolean
  selectedPlanIndex: number
  isCustomerSubscribed: boolean
  couponDetails: {
    amount_off: null | number | string
    percent_off: null | number | string
  }
  userCreditsData: Array<any>
  comboOffersData: Array<any>
}
const initialState: IState = {
  isPaymentApiLoading: false,
  strProductsList: [],
  priceModalState: false,
  selectedPlanIndex: -1,
  isCustomerSubscribed: false,
  couponDetails: {
    amount_off: null,
    percent_off: null,
  },
  userCreditsData: [],
  comboOffersData: [],
}

export const createStripeCustomerObject = createAsyncThunk(
  'stripeCustomer/create',
  async (args: any, { rejectWithValue }) => {
    try {
      const url = `payment/v1/stripe/customer`
      const { data } = await post(url, {
        username: args?.username,
        customerName: args?.customerName,
      })
      return data
    } catch (err) {
      console.log(err)
      return rejectWithValue(err)
    }
  }
)

export const createCheckoutSession = createAsyncThunk(
  'stripe/checkout-session',
  async (args: any) => {
    try {
      const url = `payment/v1/stripe/checkout`
      const { data } = await post(url, {
        ...args.args,
        customerId: args?.customerId,
      })
      return data
    } catch (err) {
      console.log(err)
    }
  }
)

export const purchaseProduct = createAsyncThunk(
  'stripe/checkout-session',
  async (args: any, { dispatch }) => {
    try {
      let res, strCustomerId

      // if str customer id already exists
      if (!args.strCId) {
        res = await dispatch(createStripeCustomerObject(args))
      } else {
        strCustomerId = args.strCId
      }
      const checkoutApiResponse = await dispatch(
        createCheckoutSession({
          args,
          customerId: !args.strCId
            ? res?.payload?.message === 'CUSTOMER CREATED'
              ? res?.payload?.data?.id
              : res?.payload?.response?.data?.data?.id
            : strCustomerId,
        })
      )
      return checkoutApiResponse
    } catch (err) {
      console.log(err)
    }
  }
)

export const getAllStrProducts = createAsyncThunk(
  'strProducts/get',
  async ({ hasMembership }: { hasMembership: boolean }) => {
    const url = `payment/v1/stripe/products?hasMembership=${hasMembership}`
    try {
      const { data } = await get(url)
      return data
    } catch (err) {
      console.log(err)
    }
  }
)
export const getUserCreditsInfo = createAsyncThunk(
  'user/get-credits',
  async () => {
    const url = `payment/v1/user/credit`
    try {
      const { data } = await get(url)
      return data
    } catch (err) {
      console.log(err)
    }
  }
)
export const getAllComboOffers = createAsyncThunk(
  'get/combo-offers',
  async () => {
    const url = `payment/v1/combo-offers`
    try {
      const { data } = await get(url)
      return data
    } catch (err) {
      console.log(err)
    }
  }
)

export const cancelUserSubscription = createAsyncThunk(
  'user/cancel-subscription',
  async () => {
    const url = `payment/v1/stripe/subscription/cancel`
    try {
      const { data } = await post(url)
      return data
    } catch (err) {
      console.log(err)
    }
  }
)

export const paymentSlice = createSlice({
  name: paymentSliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(purchaseProduct.pending, (state) => {
      state.isPaymentApiLoading = true
    })
    builder.addCase(purchaseProduct.fulfilled, (state) => {
      state.isPaymentApiLoading = false
    })
    builder.addCase(purchaseProduct.rejected, (state) => {
      state.isPaymentApiLoading = false
    })
    builder.addCase(getAllComboOffers.pending, (state) => {
      state.isPaymentApiLoading = true
    })
    builder.addCase(getAllComboOffers.fulfilled, (state, action) => {
      state.isPaymentApiLoading = false
      state.comboOffersData = action.payload?.data || []
    })
    builder.addCase(getAllComboOffers.rejected, (state) => {
      state.isPaymentApiLoading = false
    })
    builder.addCase(getUserCreditsInfo.pending, (state) => {
      state.isPaymentApiLoading = true
    })
    builder.addCase(getUserCreditsInfo.fulfilled, (state, action) => {
      state.isPaymentApiLoading = false
      state.userCreditsData = action.payload?.data
    })
    builder.addCase(getUserCreditsInfo.rejected, (state) => {
      state.isPaymentApiLoading = false
    })
    builder.addCase(getAllStrProducts.fulfilled, (state, action) => {
      state.strProductsList = action.payload?.Data?.list
      state.isCustomerSubscribed = action.payload?.isCustomerSubscribed
      state.couponDetails = action.payload?.couponDetails
    })
    builder.addCase(cancelUserSubscription.pending, (state) => {
      state.isPaymentApiLoading = true
    })
    builder.addCase(cancelUserSubscription.fulfilled, (state, action) => {
      state.isPaymentApiLoading = false
      console.log(action.payload)
    })
    builder.addCase(cancelUserSubscription.rejected, (state) => {
      state.isPaymentApiLoading = false
    })
  },
})

export const paymentReducer = paymentSlice.reducer

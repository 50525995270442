import styled from 'styled-components'

export const DownloadPdfStyles = styled.div<{
  themeData: any
  themePrimaryColor: string
}>`
	h1,
	h2,
	h3,
	p,
	strong,
	span {
    font-family: "Montserrat", sans-serif;
		font-size: 2rem;
	}
	h1 {
		font-size: 3rem;
    font-weight: 500;
	}

  #pdfs-wrapper {
    // display: none;
    margin-top: 50vh;
  }
	.pdf-container {
    // display: none;
		height: 21cm;
		width: 29.7cm;
		border: 20px solid #ff7200;
	}

	.heading-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: ${(props) => props?.themePrimaryColor}0.3);
		padding: 40px;
	}

	
	.info-points p {
		padding: 0 5px;
	}

	.qst-container {
		padding: 20px;
	}

	.qst-heading {
		font-size: 2rem;
		text-transform: uppercase;
    font-weight: 500;
	}

	.qst {
		margin-top: 15px;
		font-style: italic;
	}

	.qst-q {
		color: ${(props) => props?.themePrimaryColor}1);
		font-size: 20px;
    font-weight: 500;
	}
	.ans-container {
		display: flex;
		padding: 10px 0px;
	}

	.my-ans {
		margin-right: 50px;
		color: grey;
		font-size: 20px;
	}
	.ans {
		font-size: 20px;
		text-transform: capitalize;
		color: black;
	}
	.right-ans {
		font-size: 20px;
	}
  .answer-icon {
    height: 30px;
    width: 30px;
  }
	.image-container {
		background-color: ${(props) => props?.themePrimaryColor}0.3);
	}

	.img-heading {
		font-size: 3rem;
    font-weight: 500;
		text-transform: uppercase;
		color: white;
		padding: 20px;
	}
	.image-wrapper {
		display: flex;
		padding: 20px 20px;
		flex-wrap: wrap;
	}
	.uploaded-imgs {
		width: 300px;
		height: 250px;
		border-radius: 10px;
		border: 5px solid white;
		margin: 5px 10px;
	}
  .sign-font{
    font-family: "Mrs Saint Delafield", cursive !important;
    font-size: 3rem;
  }
`
// { [key: string]: any }
export const TableCardWrapperStyles = styled.div<{ theme: any }>`
  ${() =>
    // props
    {
      // console.log(props)
      return ``
    }}
  width: 21cm;
  height: 29cm;
  overflow: hidden;

  .table-card-side-wrapper {
    height: 100%;
    width: 100%;
    background: ${(props) => props?.theme?.color};
    background: ${(props) =>
      `linear-gradient(180deg, ${props?.theme?.color} 0%, rgba(255,255,255,1) 100%)`};
  }
  .table-card-bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .table-card-side-wrapper .esstart-logo {
    flex-basis: 30%;
    img {
      width: 15rem;
      height: 3rem;
      object-fit: contain;
    }
  }
  .table-card-side-wrapper .scan-qr-txt {
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.7rem;
    flex-basis: 60%;
  }
  .table-card-side-wrapper .scan-qr-txt span {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
  .table-card-side-wrapper .show-name {
    position: absolute;
    top: 2rem;
    left: 50%;
    width: 85%;
    transform: translate(-50%, 0%);
    font-weight: 600;
    border-radius: 0.8rem;
    font-size: 2.5rem;
    background-color: #fcfcfc;
    font-family: 'Dancing Script', cursive !important;
    padding: 2rem 2.5rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .height-50-perc {
    height: 50%;
  }

  .qr-code-wrapper__top-section {
    padding-bottom: 1rem;
    border-bottom: 1px solid #202020;
    margin-bottom: 1rem;
  }
  .table-card-side-wrapper .qr-code-wrapper {
    position: absolute;
    text-align: center;
    width: 85%;
    bottom: 3rem;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: #fcfcfc;
    padding: 2rem 2.5rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 0.8rem;
  }
  .table-card-side-wrapper .qr-code-wrapper .qr-code-image {
    flex-basis: 30%;
  }

  .qr-code-image img {
    width: 100%;
  }
  .website-url {
    font-size: 1.2rem;
    word-break: break-all;
  }
  .reverse-component {
    transform: rotate(180deg);
  }
  .divider {
    height: 0.625rem;
    width: 100%;
    background-color: #020202;
  }
  // @media print {
  // 	@page {
  // 		size: portrait;
  // 		margin: 0;
  // 		size: A4 portrait;
  // 	}
  // }
`

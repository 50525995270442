import reducerRegistry from './reducerRegistry'
import { myDevicesSliceName, myDeviceReducer } from './mydevices'
import { productName, productReducer } from './products'
import { settingName, settingReducer } from './settings'
import { authSliceName, authReducer } from './auth'
import { paymentSliceName, paymentReducer } from './payment'
import { tradeshowSliceName, tradeshowReducer } from './tradeshow'

reducerRegistry.register(myDevicesSliceName, myDeviceReducer)
reducerRegistry.register(productName, productReducer)
reducerRegistry.register(settingName, settingReducer)
reducerRegistry.register(authSliceName, authReducer)
reducerRegistry.register(paymentSliceName, paymentReducer)
reducerRegistry.register(tradeshowSliceName, tradeshowReducer)

import './style.css'
import appLogo from '../../../../../assets/logo/web_logo.svg'
import { QrCodeImage } from '../../..'

export const QrDetailCard = ({ memberInfo }: any) => {
  return (
    <>
      {!!memberInfo?.brandImgS3Key ? (
        <img
          src={`${
            process.env.REACT_APP_API_URL
          }/image-handler/v2/image-handler?userId=${
            memberInfo.userId
          }&activityId=${memberInfo._id}&objectName=${memberInfo?.brandImgS3Key
            ?.split('/')
            ?.pop()}&width=800&height=800&type=membermailer`}
          className="app-logo"
          alt="brand logo"
        />
      ) : (
        <img className="app-logo" src={appLogo} alt="website-logo" />
      )}
      <p className="qr-code-txt">SCAN HERE</p>
      <QrCodeImage className="qr-code-img" />
      {/* <div className="qr-subtext">
        Or enter this activity code to join an event
      </div> */}
      {/* <div className='poster-activiy-code'>
				{memberInfo?.activityCode}
				<p>Activity Code</p>
			</div> */}
    </>
  )
}

export const QrCodeDetails = ({ memberInfo }: any) => {
  return (
    <>
      {!!memberInfo?.brandImgS3Key ? (
        <img
          src={`${
            process.env.REACT_APP_API_URL
          }/image-handler/v2/image-handler?userId=${
            memberInfo.userId
          }&activityId=${memberInfo._id}&objectName=${memberInfo?.brandImgS3Key
            ?.split('/')
            ?.pop()}&width=800&height=800&type=membermailer`}
          className="app-logo"
          alt="brand logo"
        />
      ) : (
        <img className="app-logo" src={appLogo} alt="website-logo" />
      )}

      <p className="qr-code-txt">SCAN HERE</p>
      <QrCodeImage className="qr-code-img mb-3" />
      {/* <div className="qr-subtext">
        Or enter this activity code to join an event
      </div> */}
      {/* <div className='poster-activiy-code'>{memberInfo?.activityCode}</div> */}
    </>
  )
}

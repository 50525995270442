import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { get, post, deletek, axiosAuth } from 'esstart-components'
import toast from 'react-hot-toast'
import timezones from '../json/Timezones.json'
import Cookies from 'js-cookie'

export const productName = 'products'

interface Product {
  cardImgURL: string
  _id: string
  displayTitle: string
  distinctName: string
  moreInfoHTML: string
  moreInfoURL: string
  shortDesc2: string
  shortDesc1: string
  videoURL: boolean
  showDate: boolean
  showName: number
  uniqueCode: string
  securityNumbers: Array<number>
}

const initialState = {
  productsList: [] as Product[],
  loading: false,
  allProductList: [] as Product[],
  selectedProduct: null,
  creatingProduct: false,
  createErrorResponse: false,
  allProductsDates: [],
  showUnUsedEvents: true,
  securityNumbers: [],
  memberInfo: null,
}

// const getHeaders = () => ({
//   headers: {
//     Authorization: localStorage.getItem('accessToken') as string,
//     Accept: "application/json"
//   }
// })

export const getProducts = createAsyncThunk('products/getAll', async () => {
  const url = `/my/v1/products`
  const res = await get(url)
  return res?.data?.data || []
})

export const getSecurityNumber = createAsyncThunk(
  'common-slideshow/get-numbers',
  async () => {
    const url = `/display/v1/public-access-request`
    const res = await get(url)
    return res?.data?.data
  }
)
export const approveCommonSlideshowRequest = createAsyncThunk(
  'common-slideshow/approve',
  async (args: any) => {
    try {
      const url = `display/v1/approve-public-access`
      const { data } = await post(url, args)
      return data
    } catch (err) {
      console.error(err)
    }
  }
)

export const removeMyProduct = createAsyncThunk(
  'products/remove',
  async (args: any, thunkAPI: any) => {
    const { removeAPIurl } = args
    const res = await deletek(`${removeAPIurl}`)
    thunkAPI.dispatch(getProducts())
    return res?.data
  }
)

export const createProduct = createAsyncThunk(
  'products/createProduct',
  async (args: any) => {
    const { createAPIurl } = args
    const date = new Date(Date.now() + 1000 * 60 * 60 * 24 * 14) // 14 days from today
    const isoDate = date.toISOString()
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const { data } = await post(createAPIurl, {
      showDate: isoDate,
      timeZone: timezones.filter((i) => i.utc.includes(currentTimeZone))[0]
        .text,
    })
    return { createAPIurl, data }
  }
)

export const getAllProductList = createAsyncThunk(
  'product-list/getAll',
  async () => {
    const res = await get('/common/v1/product-list')
    return res?.data?.data || []
  }
)

export const getProduct = createAsyncThunk(
  'product-list/get',
  async (args: any) => {
    const { productId } = args
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/common/v1/product-list?productId=${productId}`
    )
    const data = await response.json()
    return data.data
  }
)
export const getProductDetails = createAsyncThunk(
  'product-list/get-details',
  async (args: any) => {
    const { productId } = args
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/common/v1/product-list?productId=${productId}`
    )
    const data = await response.json()
    return data.data
  }
)

export const acceptInvite = createAsyncThunk(
  'moderator/accept-invite',
  async ({ name, code }: any, { dispatch, getState }) => {
    const store: any = getState()
    try {
      const url = 'accept-invite'
      const { data } = await axiosAuth.post(
        url,
        { name, code },
        {
          headers: {
            Authorization: store.auth.accessToken,
          },
        }
      )
      data?.status === 'success' ? dispatch(getProducts()) : console.log('')
    } catch (err) {
      console.log(err)
      //@ts-ignore
      err?.response?.status === 404
        ? toast('You have entered an invalid code', {
            style: {
              backgroundColor: '#fcd2d6',
              color: '#861d26',
            },
          })
        : (() => {})()
    }
  }
)

export const createSlideshowFromTemplate = createAsyncThunk(
  'product/create-from-template',
  async (args: any, thunkAPI: any) => {
    const url = `sshow/v1/create-slideshow-from-template`
    const { data } = await post(url, args)
    await thunkAPI.dispatch(getProducts())
    return { ...data, ...args }
  }
)

interface IMailerId {
  mailerId: string
}
export const getMembershipInfo = createAsyncThunk(
  'mailer/get',
  async (args: IMailerId) => {
    const url = `/member-mailer/v1?mailerId=${args?.mailerId}`
    try {
      const { data } = await get(url)
      return data
    } catch (err) {
      console.error(err)
    }
  }
)

export const productsSlice = createSlice({
  name: productName,
  initialState,
  reducers: {
    setShowUnUsedEvents(state, action) {
      state.showUnUsedEvents = action?.payload
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getProducts.pending, (state: any) => {
      state.loading = true
    })
    builder.addCase(getProducts.rejected, (state: any) => {
      state.loading = false
    })
    builder.addCase(getProducts.fulfilled, (state: any, action: any) => {
      state.loading = false
      state.productsList = action.payload
      state.allProductsDates = action.payload?.map((i: any) =>
        i?.showDate?.slice(0, 10)
      )
    })
    builder.addCase(getAllProductList.pending, (state: any) => {
      state.loading = true
    })
    builder.addCase(getAllProductList.rejected, (state: any) => {
      state.loading = false
    })
    builder.addCase(getAllProductList.fulfilled, (state: any, action: any) => {
      state.loading = false
      state.allProductList = action.payload.sort(
        (a: any, b: any) => a.position - b.position
      )
    })
    builder.addCase(getProduct.pending, (state: any) => {
      state.loading = true
    })
    builder.addCase(getProduct.rejected, (state: any) => {
      state.loading = false
    })
    builder.addCase(getProduct.fulfilled, (state: any, action: any) => {
      state.loading = false
      state.selectedProduct = action.payload
    })
    builder.addCase(createProduct.pending, (state: any) => {
      state.creatingProduct = true
      state.createErrorResponse = false
    })
    builder.addCase(createProduct.rejected, (state: any) => {
      state.creatingProduct = false
      state.createErrorResponse = true
    })
    builder.addCase(createProduct.fulfilled, (state: any) => {
      state.creatingProduct = false
      state.selectedProduct = null
      state.createErrorResponse = false
    })
    builder.addCase(acceptInvite.pending, (state: any) => {
      state.loading = true
    })
    builder.addCase(acceptInvite.fulfilled, (state: any) => {
      state.loading = false
    })
    builder.addCase(acceptInvite.rejected, (state: any) => {
      state.loading = false
    })
    builder.addCase(removeMyProduct.pending, (state: any) => {
      state.loading = true
    })
    builder.addCase(removeMyProduct.fulfilled, (state: any) => {
      state.loading = false
    })
    builder.addCase(removeMyProduct.rejected, (state: any) => {
      state.loading = false
    })
    builder.addCase(createSlideshowFromTemplate.pending, (state: any) => {
      state.loading = true
    })
    builder.addCase(createSlideshowFromTemplate.fulfilled, (state: any) => {
      state.loading = false
    })
    builder.addCase(createSlideshowFromTemplate.rejected, (state: any) => {
      state.loading = false
    })
    builder.addCase(getSecurityNumber.pending, (state: any) => {
      state.loading = true
    })
    builder.addCase(getSecurityNumber.fulfilled, (state: any, action: any) => {
      state.loading = false
      state.securityNumbers = action.payload?.requests || []
    })
    builder.addCase(getSecurityNumber.rejected, (state: any) => {
      state.loading = false
    })
    builder.addCase(approveCommonSlideshowRequest.pending, (state: any) => {
      state.loading = true
    })
    builder.addCase(approveCommonSlideshowRequest.fulfilled, (state: any) => {
      state.loading = false
    })
    builder.addCase(approveCommonSlideshowRequest.rejected, (state: any) => {
      state.loading = false
    })
    builder.addCase(getMembershipInfo.pending, (state: any) => {
      state.loading = true
    })
    builder.addCase(getMembershipInfo.fulfilled, (state: any, action: any) => {
      state.loading = false
      state.memberInfo = action.payload?.data?.mailer
    })
    builder.addCase(getMembershipInfo.rejected, (state: any) => {
      state.loading = false
    })
  },
})
export const { setShowUnUsedEvents } = productsSlice.actions
export const productReducer = productsSlice.reducer

// ReducerRegistry.register(productName, productReducer);

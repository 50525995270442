import React from 'react'
import esstartLogo from '../../../assets/logo/web_logo.svg'
import { TableCardWrapperStyles } from './style'
import { QrCodeImage } from '..'

const hex2rgb = (hex: string) => {
  const r = parseInt(hex?.slice(1, 3), 16)
  const g = parseInt(hex?.slice(3, 5), 16)
  const b = parseInt(hex?.slice(5, 7), 16)
  return `rgba(${r},${g},${b},1)`
}

export const TableCard = React.forwardRef((props: { memberInfo: any }, ref) => {
  const TableCardsOneSide = () => {
    return (
      <div className="table-card-side-wrapper pos-relative">
        <p className="show-name">{props?.memberInfo?.brandName}</p>
        <div className="qr-code-wrapper">
          <div className="qr-code-wrapper__top-section flex-row align-items-end">
            <p className="scan-qr-txt text-start flex-grow-1">
              <span>SCAN THE QR</span>
              <br />
              and Create Memories
            </p>
            <QrCodeImage className="qr-code-image" />
          </div>
          <div className="qr-code-wrapper__bottom-section flex-row align-items-center">
            <p className="website-url text-start flex-grow-1">
              {props?.memberInfo?.socialMediaLinks?.websiteUrl || ''}
            </p>
            <div className="esstart-logo">
              {!!props?.memberInfo?.brandImgS3Key ? (
                <img
                  src={`${
                    process.env.REACT_APP_API_URL
                  }/image-handler/v2/image-handler?userId=${
                    props?.memberInfo.userId
                  }&activityId=${
                    props?.memberInfo._id
                  }&objectName=${props?.memberInfo?.brandImgS3Key
                    ?.split('/')
                    ?.pop()}&width=800&height=800&type=membermailer`}
                  alt="brand logo"
                />
              ) : (
                <img src={esstartLogo} alt="esstart logo" />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const theme: any = {
    color: hex2rgb('#FFC697'),
  }

  return (
    //@ts-ignore
    <div ref={ref}>
      <TableCardWrapperStyles className="pos-relative" theme={theme}>
        <div className="reverse-component height-50-perc">
          <TableCardsOneSide />
        </div>
        <div className="divider"></div>
        <div className="height-50-perc">
          <TableCardsOneSide />
        </div>
      </TableCardWrapperStyles>
    </div>
  )
})

import { useEffect, useState } from 'react'
import { AllUsersTabStyles, TableStyles, TradeshowPageStyles } from './style'
import { useDispatch } from 'react-redux'
import {
  deleteTradeshowUsers,
  getTradeshowUsers,
  pickWinnerFromTradeshowUsers,
} from '../../redux/tradeshow'
import { Loader, Modal } from 'esstart-components'
import { useSelector } from 'react-redux'
import { errorToast, successToast } from '../toasts'
import closeIcon from '../../assets/images/close_icon.svg'

export const TradeshowPage = () => {
  const dispatch: any = useDispatch()
  const tradeshowData = useSelector((state: any) => state?.tradeshowData)

  useEffect(() => {
    dispatch(getTradeshowUsers())
  }, [])

  return (
    <TradeshowPageStyles>
      {tradeshowData?.tradeshowApiLoading && <Loader />}
      {/* <TabPanel
        showDropdownForSmallScreen={true}
        accentColor="#009FB7"
        arrowColor="#ffffff"
        tabs={[
          {
            title: <span>All Users</span>,
            render: () => (
              <div className="tab-child">
                
              </div>
            ),
          },
          {
            title: <span>Winners</span>,
            render: () => (
              <div className="tab-child">
                <WinnersTab />
              </div>
            ),
          },
        ]}
      /> */}
      <AllUsersTab />
    </TradeshowPageStyles>
  )
}

const AllUsersTab = () => {
  const dispatch: any = useDispatch()
  const tradeshowData = useSelector((state: any) => state?.tradeshowData)

  const [deleteId, setDeleteId] = useState('')

  const deleteTradeShowUser = async () => {
    const res = await dispatch(deleteTradeshowUsers({ id: deleteId }))
    if (res?.payload?.status === 'success') {
      successToast('Record deleted successfully')
    } else {
      errorToast('Something went wrong')
    }
    setDeleteId('')
  }

  const headers = [
    { name: 'Name', keyName: 'name', showOnDesktop: true, showOnMobile: true },
    {
      name: 'Email',
      keyName: 'email',
      showOnDesktop: true,
      showOnMobile: true,
    },
    {
      name: 'Phone Number',
      keyName: 'phoneNumber',
      showOnDesktop: true,
      showOnMobile: true,
    },
    {
      name: 'Business Type',
      keyName: 'businessType',
      showOnDesktop: true,
      showOnMobile: true,
    },
    {
      name: 'Photo',
      keyName: 'photo',
      showOnDesktop: true,
      showOnMobile: false,
    },
    {
      name: 'Actions',
      keyName: 'actions',
      showOnDesktop: false,
      showOnMobile: false,
    },
  ]

  const rows = tradeshowData?.users?.map((item: any) => {
    return {
      name: item?.name || '',
      email: item?.email || '',
      phoneNumber: item?.phoneNumber || '',
      businessType: item?.businessType || '',
      photo: item?.imgS3Key ? <RenderImage imgS3Key={item?.imgS3Key} /> : <></>,
      actions: (
        <button
          className="app-pri-btn delete-btn"
          onClick={() => setDeleteId(item?.id)}
        >
          Delete
        </button>
      ),
    }
  })

  const data = {
    headers,
    rows,
  }

  const pickWinnerHandler = async () => {
    const res = await dispatch(pickWinnerFromTradeshowUsers())
    if (res?.payload?.status === 'success') {
      successToast('Winner picked successfully')
    } else {
      errorToast('Something went wrong')
    }
  }

  return (
    <AllUsersTabStyles>
      <div className="mt-4">
        {deleteId && (
          <Modal open={true} size="xl" className="pos-relative">
            <div className="delete-modal-content">
              <div className="d-flex justify-content-between align-items-center gap-4 pos-relative">
                <h2>Delete record</h2>
                <div className="close-icon" onClick={() => setDeleteId('')}>
                  &times;
                </div>
              </div>
              <div className="mt-5">
                <p>Are you sure you want to delete this record?</p>
                <div className="mt-4 d-flex justify-content-center gap-4 flex-wrap">
                  <button
                    className="app-sec-btn"
                    onClick={() => setDeleteId('')}
                  >
                    No
                  </button>
                  <button className="app-pri-btn" onClick={deleteTradeShowUser}>
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}

        <div className="text-end mb-4">
          <button
            className="app-pri-btn pick-winner-btn"
            onClick={pickWinnerHandler}
          >
            Pick Random Winner
          </button>
        </div>
        <div className="table-container">
          <TableCard data={data} />
        </div>
      </div>
    </AllUsersTabStyles>
  )
}

export const TableCard = ({ data }: any) => {
  const { headers, rows } = data

  return (
    <TableStyles>
      <div className="table-card-container">
        <div className="table-view">
          <table>
            <thead>
              <tr>
                {headers.map((header: any, index: number) => (
                  <th key={index}>{header.showOnDesktop ? header.name : ''}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row: any, rowIndex: number) => (
                <tr key={rowIndex}>
                  {headers.map((header: any, colIndex: number) => (
                    <td key={colIndex}>{row[header.keyName]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="card-view">
          {rows.map((row: any, rowIndex: number) => (
            <div key={rowIndex} className="card d-flex flex-column gap-2">
              {headers.map((header: any, colIndex: number) => {
                return (
                  <div key={colIndex}>
                    {header.showOnMobile ? (
                      <strong>{header.name}: </strong>
                    ) : null}
                    <span className="row-value">{row[header.keyName]}</span>
                  </div>
                )
              })}
            </div>
          ))}
        </div>
      </div>
    </TableStyles>
  )
}

export const RenderImage = ({ imgS3Key }: any) => {
  const [modalImage, setModalImage] = useState('')

  const imgS3KeyStrArr = imgS3Key?.split('/')
  const userId = imgS3KeyStrArr?.[0]
  const slideShowId = imgS3KeyStrArr?.[2]
  const objectName = imgS3KeyStrArr?.pop()
  const imageUrl = `${process.env.REACT_APP_IMAGE_URL}/image-handler?userId=${userId}&slideShowId=${slideShowId}&objectName=${objectName}`

  return (
    <>
      <img
        className="uploaded-photo-small cursor-pointer"
        src={`${imageUrl}&width=300&height=300`}
        alt="uploaded photo small"
        onClick={() => setModalImage(imageUrl)}
      />
      {modalImage && (
        <Modal open={true} size="xl" className="pos-relative">
          <div>
            <div className="text-end">
              <img
                className="close-icon-img cursor-pointer"
                src={closeIcon}
                onClick={() => setModalImage('')}
              />
            </div>
            <div className="mt-4">
              <img
                className="uploaded-photo-large"
                src={`${modalImage}&width=900&height=900`}
                alt="uploaded photo large"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { get, post, deletek } from 'esstart-components'

const initialState: any = {
  tradeshowApiLoading: false,
  users: [],
  winners: [],
}

const apiURL = `${process.env.REACT_APP_API_URL}/`

export const getTradeshowUsers = createAsyncThunk(
  'tradeshow/get-user',
  async () => {
    try {
      const res = await get(`${apiURL}common/trade-show-user`)
      return res?.data
    } catch (err) {
      console.error(err)
    }
  }
)

export const deleteTradeshowUsers = createAsyncThunk(
  'tradeshow/delete-user',
  async (args: { id: string }) => {
    try {
      const config = {
        data: {
          id: args?.id,
        },
      }
      const res = await deletek(`${apiURL}common/trade-show-user`, config)
      return res?.data
    } catch (err) {
      console.error(err)
    }
  }
)

export const pickWinnerFromTradeshowUsers = createAsyncThunk(
  'tradeshow/user-pick-winner',
  async () => {
    try {
      const res = await post(`${apiURL}common/trade-show-select-win`)
      return res?.data
    } catch (err) {
      console.error(err)
    }
  }
)

export const tradeshowSliceName = 'tradeshowData'

export const tradeshowSlice = createSlice({
  name: tradeshowSliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTradeshowUsers.pending, (state) => {
        state.tradeshowApiLoading = true
      })
      .addCase(getTradeshowUsers.rejected, (state) => {
        state.tradeshowApiLoading = false
      })
      .addCase(getTradeshowUsers.fulfilled, (state, action) => {
        state.tradeshowApiLoading = false
        state.users = action.payload?.data?.users || []
        state.winners = action.payload?.data?.wins || []
      })
      .addCase(deleteTradeshowUsers.pending, (state) => {
        state.tradeshowApiLoading = true
      })
      .addCase(deleteTradeshowUsers.rejected, (state) => {
        state.tradeshowApiLoading = false
      })
      .addCase(deleteTradeshowUsers.fulfilled, (state, action) => {
        state.tradeshowApiLoading = false
        state.users = state.users.filter(
          (obj: any) => obj?.id !== action.meta?.arg?.id
        )
      })
      .addCase(pickWinnerFromTradeshowUsers.pending, (state) => {
        state.tradeshowApiLoading = true
      })
      .addCase(pickWinnerFromTradeshowUsers.rejected, (state) => {
        state.tradeshowApiLoading = false
      })
      .addCase(pickWinnerFromTradeshowUsers.fulfilled, (state, action) => {
        state.tradeshowApiLoading = false
        if (action.payload?.data?.wonUser) {
          state.winners = [action.payload?.data?.wonUser, ...state.winners]
        }
      })
  },
})

export const tradeshowReducer = tradeshowSlice.reducer
